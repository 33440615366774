import './bootstrap';
import '../css/app.css';
import './Partials/icon';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Tooltip, Dropdown } from 'floating-vue';
import { ZiggyVue } from 'ziggy-js';
import * as Sentry from '@sentry/vue';

import 'floating-vue/dist/style.css';
import 'vue-select/dist/vue-select.css';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
createInertiaApp({
  title: (title) => (title ? `${title} — ${appName}` : appName),
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  progress: {
    color: '#f00',
    showSpinner: true,
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .component('v-tooltip', Tooltip)
      .component('v-dropdown', Dropdown)
      .component('font-awesome-icon', FontAwesomeIcon)
      .use(ZiggyVue);

    if (import.meta.env.VITE_VUE_SENTRY_DSN) {
      const integrations = [];
      if (import.meta.env.VITE_VUE_SENTRY_BROWSER_TRACING) integrations.push(Sentry.browserTracingIntegration());
      if (import.meta.env.VITE_VUE_SENTRY_REPLY) integrations.push(Sentry.replayIntegration());
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_VUE_SENTRY_DSN,
        integrations,
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^http:\/\/127\.0\.0\.1:10100\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });

      if (!import.meta.env.PROD) {
        console.log(`<== Sentry is active and monitoring to ${import.meta.env.VITE_VUE_SENTRY_DSN} ==>`);
      }
    }

    return app.mount(el);
  },
});
